<template>
  <div>
    <el-dialog :title="title" :visible="visible" :width="width" @close="close">
      <div class = "flexRow" style="flex-wrap:wrap">
        <div class = "flexRow">
          <div class = "conditionsFont">关键字：</div>
          <el-input v-model="keywords" style="width:200px" placeholder="优惠券名称"></el-input>
        </div>
        <div class = "flexRow" style="margin:0px 10px">
          <div class = "conditionsFont">优惠券类型：</div>
          <el-select v-model="state" style="width:200px">
            <el-option v-for="(v,i) in statelist" :key="i" :value="v.value" :label="v.label"></el-option>
          </el-select>
        </div>
        <el-button style="width:90px;height:36px" type="primary" @click="query">查询</el-button>
      </div>
      <div style="margin-top:10px">
        <el-table :data="tablelist" v-loading="tableloading" max-height="500px"  ref="table"  :row-key="rowKeys" @selection-change = "selectionChange">
          <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
          <el-table-column prop="CouponName" label="优惠券"></el-table-column>
          <el-table-column prop="UseTypeValue" label="类型"></el-table-column>
          <el-table-column prop="CouponContent" label="优惠内容"></el-table-column>
          <el-table-column prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>  
          <el-table-column prop="ReceiveLimitValue" label="限领次数"></el-table-column>
          <el-table-column prop="LeaveCount" label="剩余数量"></el-table-column>
        </el-table>
      </div>
      <div style="margin-top:10px" class = "flexRow between">
        <el-checkbox :value="allcheck" style="margin-left:15px" @change="checkall">当前页全选</el-checkbox>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="sizepage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div style="text-align:center;margin-top:20px">
        已选中 <span style="color:#f56c6c">{{checklist.length}}</span> 件
        <el-button type="primary" style="width:90px;height:36px;margin-left:15px" @click="couponsconfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  activityMarkdownGiftChoseCoupon
} from "@/api/sv1.0.0"
export default {
  props:{
    title:{
      type:String,
      default:'选择优惠券'
    },
    visible:{
      type:Boolean,
      default:false
    },
    width:{
      type:String,
      default:'70%'
    },
    thelist:{
      type:Array,
      default:()=>{
        return []
      }
    },
  },
  data () {
    return {
      keywords:'',
      state:null,
      statelist:[
        {value:null,label:'全部'},
        {value:0,label:'满减送'},
        {value:1,label:'打折券'},
        {value:2,label:'随机金额券'},
      ],
      tablelist:[],
      tableloading:false,
      // allcheck:false,
      checklist:[],
      currentPage:1,
      sizepage:10,
      total:null,

      iseffective:false,
    }
  },
  watch:{
    visible(val){
      if(val){
        // console.log(this.thelist)
        this.gettablelist()
        this.iseffective = true
        this.$nextTick(()=>{
          this.thelist.map((x)=>{
            x.Id=x.ActivityCouponId
            this.$refs.table.toggleRowSelection(x,true)
          })
        })
        
      }
    },
  },
  computed:{
    allcheck(){
      if(this.tablelist&&this.tablelist.length){
        return this.tablelist.every((v)=>{
          return this.checklist.some((x)=>{
            return v.Id == x.Id
          })
        })
      }
    },
  },
  methods: {
    close(){
      // this.visible = false
      this.keywords = ''
      this.state = null
      this.currentPage=1
      this.sizepage=10,
      this.iseffective = false
      this.$refs.table.clearSelection()
      this.$emit('close')
    },
    couponsconfirm(){
      this.$emit('confirm',this.checklist)
    },
    query(){
      this.currentPage = 1
      this.gettablelist()
    },
    rowKeys(row) {
			return row.Id
		},
    selectionChange(e){
      this.checklist = e
    },
    checkall(){
      this.$refs.table.toggleAllSelection()
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },

    async gettablelist(){
      this.tableloading = true
      try{
        let data = {
          KeyWord:this.keywords,
          UseType:this.state,
          Skip:(this.currentPage-1)*this.sizepage,
          Take:this.sizepage,
          IsUse:true
        }
        let result = await activityMarkdownGiftChoseCoupon(data)
        if(result.IsSuccess){
          this.tablelist = result.Result.Results
          this.total = result.Result.Total
          // console.log(result.Result)
          // if(this.iseffective){
          //   this.$nextTick(()=>{
          //     this.thelist.map((x)=>{
          //       x.Id=x.ActivityCouponId
          //       this.tablelist.map((v)=>{
          //         if(x.ActivityCouponId==v.Id){
          //           this.$refs.table.toggleRowSelection(v,true)
          //         }
          //       })
          //     })
          //     this.iseffective = false
          //   })
          // }
        }
      }finally{
        this.tableloading = false
      }
    },
  }
}
</script>

<style lang = "less" scoped>
  .flexRow{
    display: flex;
    flex-direction: row;
  }
  .conditionsFont{
    font-size: 14px;
    line-height: 36px;
    font-weight: bold;
    color: #606266;
  }
  .between{
    justify-content: space-between;
  }
</style>