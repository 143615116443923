<template>
	<!-- 此单选商品组件，只适用于单独规格的商品 -->
	<div class="contanier">
		<div class="filter-container title-border">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" style="width:200px" placeholder="商品名称、编码"></el-input>
			</div>
			<!--销售类型  -->
			<div class="filter-item">
				<label class="label">销售类型: </label>
				<el-select v-model="type" style="width: 250px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in typeOptions" :key="index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!--销售状态  -->
			<div class="filter-item">
				<label class="label">销售状态: </label>
				<el-select v-model="state" style="width: 250px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in stateOptions" :key="index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<!-- 销售分组 -->
			<div class="filter-item">
				<label class="label">商品分组: </label>
				<el-select v-model="group" style="width: 250px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in groupOptions" :key="index" :label="item.GroupName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<!-- 商品品牌 -->
			<div class="filter-item">
				<label class="label">商品品牌: </label>
				<el-select v-model="brand" style="width: 250px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in brandOptions" :key="index" :label="item.BrandName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin:0 30px;width:90px;" @click="handleFilter">查询</el-button>
				<el-checkbox v-model="checkedShopPro" v-if="showOnlyChoose">仅看可选商品</el-checkbox>
			</div>
		</div>
		<!-- table -->
		<div class="table-container content">
			<el-table :data="tableDataList" height='450px' style="width: 100%" ref="compSelectProTable" v-loading='loading'
			 :row-key="getRowKeys" empty-text="筛选结果为空">
				<el-table-column width="55">
					<template scope="scope">
						<el-radio :label="scope.row.Id" :disabled="IsCheckState(scope.row)" v-model="templateRadio" @change.native="getTemplateRow(scope.row)">{{&nbsp;}}</el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="Name" label="商品" width="300">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							<img :src='imgUrl+scope.row.ImgUrl' />
							<div class="right">
								<div class="name twoflows">
									<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="ProductTypeValue" label="销售类型"></el-table-column>
				<!-- <el-table-column prop="Spec" label="规格">
					<template slot-scope="scope">
						<span v-if="scope.row.Spec ==''">
							默认规格
						</span>
						<span v-else>
							{{scope.row.Spec}}
						</span>
					</template>
				</el-table-column> -->
				<el-table-column prop="ProductPrice" label="价格"></el-table-column>
				<el-table-column prop="Stock" label="库存"></el-table-column>
				<el-table-column prop="Remark" label="备注"></el-table-column>
			</el-table>

			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[5,10, 20, 30, 40, 50]" :page-size="page.size"
			 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
		</div>
		<div slot="footer" class="dialog-footer">
			<el-button class="button" @click="saveSelection" style="margin-left:10px;" type="primary">确定</el-button>
		</div>
	</div>
</template>
<script>
	import config from '@/config/index'
	import {activityCommunityGroupProductlist,activitySpellGroupproductList}  from '@/api/TurnTomySelf.js'
	import {
		productBrandList,
		productGroupList,
		channelShopchannelShopProductChoseBox
	} from "@/api/goods.js";
	import {
		activityHelpProproductList
	} from "@/api/sv3.0.0"
	export default {
		name: 'selectProduce',
		props: {
			proCheckedId: {
				type: Number,
				default: () => {
					return 0
				}
			},
			Num:{
				type: Number,
				default: () => {
					return 0
				}
			},
			selectData:{
				type: Object,
				default: () => {
					return {}
				}
			},
			couponData: {
				type: Object,
				default: () => {
					return {}
				}
			},
			url: {
				type: String,
				default: ''
			},
			showOnlyChoose: {
				type: Boolean,
				default: true
			},
		},
		data() {
			return {
				loading: false,
				imgUrl: config.IMG_BASE,
				searchKey: '',
				type: null,
				typeOptions: [{
						value: 0,
						label: '单品销售'
					},
					{
						value: 1,
						label: '组合销售'
					}
				],
				state: null,
				stateOptions: [{
						value: 1,
						label: '已上架'
					},
					{
						value: 2,
						label: '已售罄'
					},
					{
						value: 3,
						label: '已下架'
					}
				],
				group: null,
				groupOptions: [],
				brand: null,
				brandOptions: [],
				templateRadio: 0,
				page: {
					total: 0,
					current: 1,
					size: 10
				},
				tableDataList: [],
				selection: {},
				isChecked: false,
				checkedShopPro: true,
			}
		},
		created() {
			this.getInfor();
			this.getSingleList()
		},
		mounted() {
			if(this.selectData.Id){
				this.selection = this.selectData
				this.isChecked = true
			}
		},
		methods: {
			//筛选数据
			async getInfor() {
				try {
					let result = await productBrandList()
					this.brandOptions = result.Result
			
					let result1 = await productGroupList()
					this.groupOptions = result1.Result
			
				} catch (e) {
					console.log(e)
				} finally {
			
				}
			},
			async getSingleList() {
				this.loading = true;
				try {
					let data = {
						Keywords: this.searchKey,
						ProductType: this.type,
						ProductState: this.state,
						IsSelectable: this.showOnlyChoose?this.checkedShopPro:true,
						ProductGroup : this.group,
						ProductBrand : this.brand,
						StartTime: this.couponData.StartTime,
						EndTime: this.couponData.EndTime,
						ActivityFullId: this.couponData.id,
						Skip: (this.page.current - 1) * this.page.size, // 略过数据
						Take: this.page.size, // 取的数据
						//视频号选商品用的
						ProductGroupId : this.group,
						ProductBrandId : this.brand,
					};
					let result;
					if(this.Num == 1){
						// console.log('执行多人拼团商品数据')
						result = await activitySpellGroupproductList(data)
					}else if(this.Num == 2){
						// 助力pro
						result = await activityHelpProproductList(data)
					}else if(this.Num == 3){
						// 视频号商品配置选商品
						result = await channelShopchannelShopProductChoseBox(data)
					}
					else{
						// console.log('执行社群商品数据')
						result = await activityCommunityGroupProductlist(data)
					}
					this.tableDataList = result.Result.Results
					this.page.total = result.Result.Total;
					this.templateRadio = this.proCheckedId
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}
			},
			IsCheckState(row) {
				return row.IsDisabled
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val
				this.getSingleList()
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val
				this.getSingleList()
			},
			getRowKeys(row) {
				return row.id;
			},
			handleFilter() {
				
				this.page.current = 1
				this.getSingleList()
			},
			//单选按钮
			getTemplateRow(row) {
				this.isChecked = true
				this.selection = row
			},
			//关闭弹框，以及曝光选择
			saveSelection() {
				// console.log(this.selection)
				this.$emit('getSelection', this.selection, this.isChecked)
				// if (this.selection == {}) {
				// 	this.$message({
				// 		type: 'error',
				// 		message: '请选择商品'
				// 	})
				// } else {
					
				// }
			}

		}
	}
</script>
<style lang="less" scoped>
	.contanier {
		position: relative;
		overflow: hidden;
	}
	.twoflows{
		width: 180px;
		overflow:hidden; 
		text-overflow:ellipsis;
		display:-webkit-box; 
		-webkit-box-orient:vertical;
		-webkit-line-clamp:2; 
	}
	.content {
		overflow: hidden;
	}

	.dialog-footer {
		.button {
			width: 150px;
		}

		text-align: center;
		margin-top: 20px;
	}

	.dialog-name-content {
		margin: 10px 0;
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: cover;
			margin-right: 10px;
		}
	}
</style>
